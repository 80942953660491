import React from "react";
import Layout from "../components/Layout";
import { graphql, Link } from "gatsby";
import { BLOCKS } from "@contentful/rich-text-types";
import { GatsbyImage } from "gatsby-plugin-image";
import Seo from "../components/seo";
import ProjectGrid from "../components/ProjectGrid";
import Testimonials from "../components/Testimonials";
import ServiceClientsLogoBlock from "../components/ServiceClientsLogoBlock";
import ogIMage from "../images/og.png";
import TarkaLabLogo from "../images/tarkalabs-logo.svg";
import Eam360Logo from "../images/eam360-logo.svg";
// import Eam360Logo from "../images/eam-logo.png";
import BlogCtaBanner from "../components/BlogCtaBanner";
import Faq from "../components/faq";
import { StaticImage } from "gatsby-plugin-image";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import ArticleGrid from "../components/ArticleGrid";
import ProductTechnologies from "../components/ProductTechnologies";

const ServiceDetail = ({ data, location, pageContext }) => {
    const schema = [
        {
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "Sedin Technologies",
            url: "https://sedintechnologies.com/",
            sameAs: [
                "https://www.facebook.com/SedinTechnologies",
                "https://twitter.com/SedinTech",
                "https://www.instagram.com/lifeatsedin/",
                "https://www.linkedin.com/company/sedin-technologies/",
            ],

            address: {
                "@type": "PostalAddress",
                addressLocality: "Chennai",
                postalCode: "600083",
                streetAddress:
                    "# A1/1, 49th Street, 7th Avenue, Ashok Nagar, Chennai, TN, India, 600083",
            },
        },
        {
            "@context": "https://schema.org/",
            "@type": "BreadcrumbList",
            itemListElement: [
                {
                    "@type": "ListItem",
                    position: 1,
                    name: "Home page",
                    item: "https://sedintechnologies.com/",
                },
                {
                    "@type": "ListItem",
                    position: 2,
                    name: "Services",
                    item: "https://sedintechnologies.com/services/",
                },
                {
                    "@type": "ListItem",
                    position: 3,
                    name: data.pageData.title,
                    item: `https://sedintechnologies.com${location.pathname}`,
                },
            ],
        },
    ];
    let salesforceUrl = location.pathname.includes("salesforce");
    let productPlatformUrl = location.pathname.includes("product-and-platform-services");
    let maximoEAM = location.pathname.includes("maximo-enterprise-asset-management");

    let engineeringLeaders = [];
    let clientTestimonials = [];

    data.allContentfulDivisions.nodes.forEach((element) => {
        if (element.testimonials) clientTestimonials.push(...element.testimonials);
        if (element.members) engineeringLeaders.push(...element.members);
    });

    clientTestimonials = clientTestimonials.filter((t) => {
        if (!t.testimonialType) return t;
        return false;
    });

    engineeringLeaders = engineeringLeaders.filter((l) => {
        if (l.founder) return l;
        return false;
    });
    const seoTitle = data.pageData.metaTitle ? data.pageData.metaTitle : data.pageData.title;
    const seoDescription = data.pageData.metaDescription
        ? data.pageData.metaDescription.metaDescription
        : data.pageData.description.description;

    const ServicesOgImage = data.pageData.ogImage.gatsbyImageData?.images.fallback.src
        ? data.pageData.ogImage.gatsbyImageData?.images.fallback.src
        : ogIMage;

    const faqContent = data.pageData.faqContent;
    const bgImage = data.pageData.heroBanner?.gatsbyImageData?.images.fallback.src;
    const NavbarTheme = {
        color: "light",
        paddingTop: "0px",
    };
    const options = {
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => {
                return <p className="text-p3 text-clr-secondary gap-05x">{children}</p>;
            },
            [BLOCKS.UL_LIST]: (node, children) => {
                return <ul className="text-p3 text-clr-secondary">{children}</ul>;
            },
            [BLOCKS.OL_LIST]: (node, children) => <ol>{children}</ol>,
            [BLOCKS.LIST_ITEM]: (node, children) => <li>{children}</li>,
        },
    };

    return (
        <>
            <Layout NavbarTheme={NavbarTheme}>
                <Seo
                    schemaMarkup={schema}
                    title={seoTitle}
                    description={seoDescription}
                    url={location.href}
                    image={ServicesOgImage}
                />

                <section
                    className="service-hero "
                    style={{
                        background: `url(${bgImage})`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center center",
                    }}
                >
                    <div className="container container--banner">
                        <div className="service-hero__text">
                            {/* <h1 className="section-tag gap-2x">{data.pageData.title}</h1> */}
                            <div className="gap-2x service-hero__text-breadcrumbs text-fw-normal">
                                <Link to={`/services`} className="text-decor-none">
                                    Services
                                </Link>
                                /
                                <Link
                                    className="text-decor-none"
                                    to={`/services/${pageContext.serviceSlug.slug}`}
                                >
                                    {pageContext.serviceTitle.title}
                                </Link>
                            </div>
                            <h1 className="text-h1 service-hero__text-header text-fw-medium gap-2x">
                                {data.pageData.heading}
                            </h1>
                            <p className="text-p1 service-hero__text-description text-fw-regular gap-2x">
                                {data.pageData.description.description}
                            </p>
                            <div className="">
                                {maximoEAM ? (
                                    <Link className="sdn-cta-btn" to="/contact">
                                        Contact Maximo experts
                                    </Link>
                                ) : (
                                    <Link className="sdn-cta-btn" to="/contact">
                                        Contact us
                                    </Link>
                                )}
                            </div>
                        </div>
                    </div>
                </section>

                <section className="service-clients">
                    <div className="container container--first">
                        <ServiceClientsLogoBlock
                            title={
                                "Trusted by 1000+ global businesses from startups to enterprises"
                            }
                            description={data.pageData.clientsDescription.clientsDescription}
                            logos={data.pageData.clientsLogo}
                        />
                    </div>
                </section>
                {data.pageData.serviceEaiTitle && data.pageData.serviceEaiContent && (
                    <section className="service-eai">
                        <div className="container container--mid-v2">
                            <div className="service-eai__text">
                                <h2 className="section-solution__text text-h1 text-fw-medium text-clr-primary gap-3x">
                                    {data.pageData.serviceEaiTitle}
                                </h2>
                                <div className="text-p2 text-clr-secondary text-fw-normal gap-3x">
                                    {renderRichText(data.pageData.serviceEaiContent, options)}
                                </div>
                            </div>
                        </div>
                    </section>
                )}
                {data.pageData.productServiceTitle && data.pageData.productServiceContent && (
                    <section className="service-eai">
                        <div className="container container--mid-v2">
                            <div className="service-eai__text">
                                <h2 className="section-solution__text text-h1 text-fw-medium text-clr-primary gap-3x">
                                    {data.pageData.productServiceTitle}
                                </h2>
                                <div className="text-p2 text-clr-secondary text-fw-normal gap-3x">
                                    {renderRichText(data.pageData.productServiceContent, options)}
                                </div>
                            </div>
                        </div>
                    </section>
                )}
                {productPlatformUrl && (
                    <section className="product-building">
                        <div className="container container--mid-v2">
                            <h1 className="service-vision__text text-h1 text-fw-medium text-clr-primary gap-4x">
                                Building and Sustaining Successful Products
                            </h1>
                            <div className="product-building__grid">
                                <div className="product-building__grid-item">
                                    <div className="product-building__grid-item__image">
                                        <Link to="https://eam360.com/" target="_blank">
                                            <img
                                                src={Eam360Logo}
                                                // src="../images/eam-logo.png"
                                                alt="eam360-logo"
                                                loading="lazy"
                                            />
                                            {/* <StaticImage
                                                // src={Eam360Logo}
                                                src="../images/eam-logo.png"
                                                alt="eam360-logo"
                                                loading="lazy"
                                            /> */}
                                        </Link>
                                    </div>
                                    <div className="product-building__grid-item__content">
                                        <p className="text-p2 text-fw-regular text-clr-secondary gap-05x">
                                            Our extensive experience in product development is
                                            exemplified by EAM360, an industry leader in mobile
                                            solutions for Maximo Enterprise Asset Management.
                                        </p>
                                        <p className="text-p2 text-fw-regular text-clr-secondary">
                                            We have developed and sustained this successful product
                                            throughout its lifecycle, giving us a firsthand
                                            understanding of what it takes to create a successful
                                            product.
                                        </p>
                                    </div>
                                </div>
                                <div className="product-building__grid-item">
                                    <div className="product-building__grid-item__image">
                                        <Link to="https://tarkalabs.com/" target="_blank">
                                            <img
                                                src={TarkaLabLogo}
                                                alt="tarka-lab-logo"
                                                loading="lazy"
                                            />
                                        </Link>
                                    </div>
                                    <div className="product-building__grid-item__content">
                                        <p className="text-p2 text-fw-regular text-clr-secondary gap-05x">
                                            Tarka Labs leads our product engineering services,
                                            staffed with experts in enterprise architecture and
                                            technology consulting.
                                        </p>
                                        <p className="text-p2 text-fw-regular text-clr-secondary">
                                            With over 200 years of cumulative experience in high-end
                                            product lifecycle work, our consultants ensure your
                                            product's critical engineering needs are met.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                )}
                {data.pageData.solutions && (
                    <div className="section-solution">
                        <div className="container container--last">
                            <h2 className="section-solution__text text-h1 text-fw-medium text-clr-primary gap-4x">
                                {data.pageData.solutionHeading}
                            </h2>
                            <div className="section-solution__content">
                                {data.pageData.solutions.map((s, index) => (
                                    <div key={s.id} className="section-solution__content-item">
                                        <div className="section-solution__content-item__solution-info">
                                            <div className="section-solution__content-item__solution-info__name">
                                                <span>0{index + 1}</span>
                                                {s.title}
                                            </div>
                                            <h3 className="section-solution__content-item__solution-info__title">
                                                {s.name}
                                            </h3>
                                            <div className="section-solution__content-item__solution-info__content">
                                                <div>
                                                    <p className="section-solution__content-item__solution-info__description gap-2x">
                                                        {s.description.description}
                                                    </p>
                                                    {s.content && (
                                                        <div>
                                                            <Link
                                                                to={s.slug}
                                                                className="sdn-cta-btn"
                                                            >
                                                                Learn more
                                                            </Link>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="section-solution__content-item__solution-features">
                                                    {s.solutionLists.map((f, index) => (
                                                        <div key={`features_${s.id}_${index}`}>
                                                            {f}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                )}
                {salesforceUrl && (
                    <>
                        <section className="service-partners">
                            <div className="container">
                                <div className="text">
                                    <h2 className="text-h1 text-fw-medium text-clr-primary gap-3x">
                                        Our Salesforce Team Certifications
                                    </h2>
                                    <p className="text-p2 text-fw-regular text-clr-secondary gap-4x">
                                        Leveraging Salesforce’s multi-cloud solutions, our certified
                                        and experienced consultants have been solving our clients'
                                        most complex business problems.
                                    </p>
                                </div>
                                <div className="partners-grid">
                                    <div className="partner-logo">
                                        <StaticImage
                                            src="../images/salesforce/application.png"
                                            placeholder="blurred"
                                            alt="clients"
                                            loading="lazy"
                                        />
                                    </div>
                                    <div className="partner-logo">
                                        <StaticImage
                                            src="../images/salesforce/admin.png"
                                            placeholder="blurred"
                                            alt="clients"
                                            loading="lazy"
                                        />
                                    </div>
                                    <div className="partner-logo">
                                        <StaticImage
                                            src="../images/salesforce/app-builder.png"
                                            placeholder="blurred"
                                            alt="clients"
                                            loading="lazy"
                                        />
                                    </div>
                                    <div className="partner-logo">
                                        <StaticImage
                                            src="../images/salesforce/cpq.png"
                                            placeholder="blurred"
                                            alt="clients"
                                            loading="lazy"
                                        />
                                    </div>
                                    <div className="partner-logo">
                                        <StaticImage
                                            src="../images/salesforce/data.png"
                                            placeholder="blurred"
                                            alt="clients"
                                            loading="lazy"
                                        />
                                    </div>
                                    <div className="partner-logo">
                                        <StaticImage
                                            src="../images/salesforce/experience-cloud.png"
                                            placeholder="blurred"
                                            alt="clients"
                                            loading="lazy"
                                        />
                                    </div>
                                    <div className="partner-logo">
                                        <StaticImage
                                            src="../images/salesforce/javascript.png"
                                            placeholder="blurred"
                                            alt="clients"
                                            loading="lazy"
                                        />
                                    </div>
                                    <div className="partner-logo">
                                        <StaticImage
                                            src="../images/salesforce/pardot.png"
                                            placeholder="blurred"
                                            alt="clients"
                                            loading="lazy"
                                        />
                                    </div>
                                    <div className="partner-logo">
                                        <StaticImage
                                            src="../images/salesforce/sales-cloud.png"
                                            placeholder="blurred"
                                            alt="clients"
                                            loading="lazy"
                                        />
                                    </div>
                                    <div className="partner-logo">
                                        <StaticImage
                                            src="../images/salesforce/service-cloud.png"
                                            placeholder="blurred"
                                            alt="clients"
                                            loading="lazy"
                                        />
                                    </div>
                                    <div className="partner-logo">
                                        <StaticImage
                                            src="../images/salesforce/visibility-architect.png"
                                            placeholder="blurred"
                                            alt="clients"
                                            loading="lazy"
                                        />
                                    </div>
                                    <div className="partner-logo">
                                        <StaticImage
                                            src="../images/salesforce/platform-1.png"
                                            placeholder="blurred"
                                            alt="clients"
                                            loading="lazy"
                                        />
                                    </div>
                                    <div className="partner-logo">
                                        <StaticImage
                                            src="../images/salesforce/platform-2.png"
                                            placeholder="blurred"
                                            alt="clients"
                                            loading="lazy"
                                        />
                                    </div>
                                    <div className="partner-logo">
                                        <StaticImage
                                            src="../images/salesforce/non-profit.png"
                                            placeholder="blurred"
                                            alt="clients"
                                            loading="lazy"
                                        />
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="salesforce-expertise">
                            <div className="container">
                                <div className="text">
                                    <h2 className="text-h1 text-fw-medium text-clr-primary gap-3x">
                                        Our industry expertise 
                                    </h2>
                                    <p className="text-p2 text-fw-regular text-clr-secondary gap-4x">
                                        We cater to our client’s needs in every industry; providing
                                        them with the best solutions to help unlock value from
                                        existing processes & accelerate their business to the next
                                        level. 
                                    </p>
                                </div>
                                <div className="salesforce-expertise__grid">
                                    <div className="salesforce-expertise__grid-item">
                                        <StaticImage
                                            src="../images/salesforce/manufacturing.svg"
                                            alt="icon"
                                        />
                                        <p className="text-p3 text-fw-medium text-clr-primary">
                                            Manufacturing
                                        </p>
                                    </div>
                                    <div className="salesforce-expertise__grid-item">
                                        <StaticImage
                                            src="../images/salesforce/telecom.svg"
                                            alt="icon"
                                        />
                                        <p className="text-p3 text-fw-medium text-clr-primary">
                                            Telecom
                                        </p>
                                    </div>
                                    <div className="salesforce-expertise__grid-item">
                                        <StaticImage
                                            src="../images/salesforce/media.svg"
                                            alt="icon"
                                        />
                                        <p className="text-p3 text-fw-medium text-clr-primary">
                                            HR & Media
                                        </p>
                                    </div>
                                    <div className="salesforce-expertise__grid-item">
                                        <StaticImage
                                            src="../images/salesforce/ngo.svg"
                                            alt="icon"
                                        />
                                        <p className="text-p3 text-fw-medium text-clr-primary">
                                            Non Profits
                                        </p>
                                    </div>
                                    <div className="salesforce-expertise__grid-item">
                                        <StaticImage
                                            src="../images/salesforce/professional.svg"
                                            alt="icon"
                                        />
                                        <p className="text-p3 text-fw-medium text-clr-primary">
                                            Professional Services
                                        </p>
                                    </div>
                                    <div className="salesforce-expertise__grid-item">
                                        <StaticImage
                                            src="../images/salesforce/banking.svg"
                                            alt="icon"
                                        />
                                        <p className="text-p3 text-fw-medium text-clr-primary">
                                            Banking, Insurance
                                        </p>
                                    </div>
                                    <div className="salesforce-expertise__grid-item">
                                        <StaticImage
                                            src="../images/salesforce/healthcare.svg"
                                            alt="icon"
                                        />
                                        <p className="text-p3 text-fw-medium text-clr-primary">
                                            Healthcare, Automotive
                                        </p>
                                    </div>
                                    <div className="salesforce-expertise__grid-item">
                                        <StaticImage
                                            src="../images/salesforce/construction.svg"
                                            alt="icon"
                                        />
                                        <p className="text-p3 text-fw-medium text-clr-primary">
                                            Engineering & Construction
                                        </p>
                                    </div>
                                    <div className="salesforce-expertise__grid-item">
                                        <StaticImage
                                            src="../images/salesforce/industry.svg"
                                            alt="icon"
                                        />
                                        <p className="text-p3 text-fw-medium text-clr-primary">
                                            Industrial Services
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </>
                )}

                {data.pageData.engagementVision && (
                    <section className="service-vision">
                        <div className="container container--py">
                            <h2 className="service-vision__text text-h1 text-fw-medium text-clr-primary gap-4x">
                                {data.pageData.engagementHeading}
                            </h2>
                            <div className="service-vision__content">
                                {data.pageData.engagementVision.map((v) => (
                                    <div key={v.id} className="service-vision__content-item">
                                        <div className="service-vision__content-item__name">
                                            {v.name}
                                        </div>
                                        <div className="service-vision__content-item__description">
                                            {v.description.description}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </section>
                )}
                <section className="service-statistics">
                    <div className="container container--py">
                        <div className="service-statistics__grid">
                            <div className="service-statistics__grid-text">
                                <h2 className="text-h2 text-fw-medium text-clr-primary gap-2x">
                                    Statistics
                                </h2>
                            </div>
                            <div className="service-statistics__grid-content">
                                {data.pageData.statistics.map((s) => (
                                    <div
                                        key={s.id}
                                        className="service-statistics__grid-content-item"
                                    >
                                        <div className="service-statistics__grid-content-item__value">
                                            {s.value}
                                        </div>
                                        <div className="service-statistics__grid-content-item__name">
                                            {s.name}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </section>

                {data.allContentfulCasestudies.nodes.length > 0 && (
                    <section className="service-casestudies">
                        <div className="container container--mid">
                            <h2 className="service-casestudies__text text-h1 text-fw-medium text-clr-primary gap-4x">
                                50+ global enterprises fuel their growth engines with Sedin experts
                                everyday.
                            </h2>
                            <div className="service-casestudies__projects">
                                <ProjectGrid
                                    CaseStudiesData={data.allContentfulCasestudies.nodes}
                                />
                            </div>
                        </div>
                    </section>
                )}
                <section>
                    <div className="container container--py">
                        {clientTestimonials.length > 0 && (
                            <Testimonials testimonialData={clientTestimonials} />
                        )}
                    </div>
                </section>
                {data.pageData.vision && (
                    <section className="service-vision">
                        <div className="container container--py">
                            <h2 className="service-vision__text text-h1 text-fw-medium text-clr-primary gap-4x">
                                {data.pageData.visionHeading}
                            </h2>
                            <div className="service-vision__content">
                                {data.pageData.vision.map((v) => (
                                    <div key={v.id} className="service-vision__content-item">
                                        <div className="service-vision__content-item__name">
                                            {v.name}
                                        </div>
                                        <div className="service-vision__content-item__description">
                                            {v.description.description}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </section>
                )}
                {engineeringLeaders.length > 0 && (
                    <section className="service-leaders">
                        <div className="container container--py">
                            <h2 className="service-leaders__text text-h1 text-fw-medium text-clr-primary gap-4x">
                                {data.pageData.title} leaders
                            </h2>
                            <div className="service-leaders__grid">
                                {engineeringLeaders.map((l) => (
                                    <div key={l.id} className="service-leaders__item">
                                        <div className="service-leaders__item-avatar">
                                            <GatsbyImage
                                                className="service-leaders__item-avatar__image"
                                                image={l.colorLogo.gatsbyImageData}
                                                alt={l.colorLogo.title}
                                                loading="lazy"
                                            />
                                        </div>
                                        <div className="service-leaders__item-content">
                                            <div className="service-leaders__item-content__name">
                                                {l.name}
                                            </div>
                                            <div className="service-leaders__item-content__designation">
                                                {l.designation}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </section>
                )}
                {data.pageData.toolsAndTechnologiesLogo && (
                    <section className="service-tools">
                        <div className="container container--mid-v2">
                            <h2 className="service-leaders__text text-h1 text-fw-medium text-clr-primary gap-4x">
                                Collaborative Tools and Technologies That Moves Work Forward
                            </h2>
                            <ServiceClientsLogoBlock
                                logos={data.pageData.toolsAndTechnologiesLogo}
                            />
                        </div>
                    </section>
                )}
                {productPlatformUrl && (
                    <section className="product-tool-tech">
                        <div className="container container--mid-v2">
                            <h1 className="service-vision__text text-h1 text-fw-medium text-clr-primary gap-4x">
                                Collaborative Tools and Technologies That Moves Work Forward
                            </h1>
                            <div>
                                <ProductTechnologies />
                            </div>
                        </div>
                    </section>
                )}
                {data.pageData.serviceMembers && (
                    <section className="service-leaders">
                        <div className="container container--py">
                            <h2 className="service-leaders__text text-h1 text-fw-medium text-clr-primary gap-4x">
                                Meet our Team
                            </h2>
                            <div className="service-leaders__grid">
                                {data.pageData.serviceMembers?.map((member, i) => (
                                    <div key={i} className="service-leaders__item">
                                        <div className="service-leaders__item-avatar">
                                            <GatsbyImage
                                                className="service-leaders__item-avatar__image"
                                                image={member.colorLogo?.gatsbyImageData}
                                                alt={member.colorLogo?.title}
                                                loading="lazy"
                                            />
                                        </div>
                                        <div className="service-leaders__item-content">
                                            <div className="service-leaders__item-content__name">
                                                {member.name}
                                            </div>
                                            <div className="service-leaders__item-content__designation">
                                                {member.designation}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </section>
                )}
                {data.allContentfulBlogs.nodes.length > 0 && (
                    <section>
                        <div className="container container--mid-v2">
                            <h2 className="service-casestudies__text text-h1 text-fw-medium text-clr-primary gap-4x">
                                Blogs
                            </h2>
                            <ArticleGrid featuredArticles={data.allContentfulBlogs.nodes} />
                        </div>
                    </section>
                )}
                {faqContent && (
                    <section>
                        <div className="container container--mid-v2">
                            {faqContent ? <Faq faq={data.pageData.faq} /> : ""}
                        </div>
                    </section>
                )}
                <section className="service-contact">
                    <div className="container container--last">
                        <BlogCtaBanner
                            title={data.pageData.callToActionBanner.header}
                            ctaLink={data.pageData.callToActionBanner.ctaSlug}
                            ctaText={data.pageData.callToActionBanner.ctaText}
                        />
                    </div>
                </section>

                {data.relatedServices.nodes.length > 0 && (
                    <section className="related-service">
                        <div className="container container--py">
                            <div className="related-service__content">
                                <div className="related-service__content-title">
                                    Related services
                                </div>
                                <div className="related-service__content-links">
                                    {data.relatedServices.nodes.map((s) => (
                                        <Link
                                            key={s.id}
                                            to={`/services/${s.slug}/`}
                                            className="sdn-link"
                                        >
                                            {s.title}
                                        </Link>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </section>
                )}
            </Layout>
        </>
    );
};

export default ServiceDetail;

export const query = graphql`
    query servicePageQuery($id: String, $relatedPages: [String]) {
        pageData: contentfulServices(id: { eq: $id }) {
            title
            heading
            description {
                description
            }
            ogImage {
                gatsbyImageData(quality: 100)
            }
            clientsHeading
            clientsDescription {
                clientsDescription
            }
            clientsLogo {
                title
                id
                gatsbyImageData(quality: 100, placeholder: BLURRED, layout: CONSTRAINED)
                file {
                    contentType
                    url
                }
            }

            faqContent
            faq {
                title
                description {
                    description
                }
            }
            heroBanner {
                gatsbyImageData(quality: 100, placeholder: BLURRED, layout: CONSTRAINED)
            }
            callToActionBanner {
                ctaText
                ctaSlug
                header
            }
            solutionHeading
            solutions {
                id
                title
                content
                slug
                name
                slug
                description {
                    description
                }
                solutionLists
            }
            statistics {
                id
                name
                value
            }
            metaTitle
            metaDescription {
                metaDescription
            }
            visionHeading
            vision {
                id
                name
                description {
                    description
                }
            }
            serviceEaiTitle
            serviceEaiContent {
                raw
            }
            toolsAndTechnologiesLogo {
                gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, quality: 100)
                title
                file {
                    url
                }
            }
            serviceMembers {
                name
                designation
                colorLogo {
                    gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, quality: 100)
                    title
                    file {
                        url
                    }
                }
            }
            engagementHeading
            engagementVision {
                id
                name
                description {
                    description
                }
            }
            productServiceTitle
            productServiceContent {
                raw
            }
        }
        allContentfulDivisions(filter: { services: { elemMatch: { id: { eq: $id } } } }) {
            nodes {
                id
                testimonials {
                    id
                    personDesignation
                    personName
                    profileImage {
                        gatsbyImageData(
                            quality: 100
                            sizes: "250"
                            layout: CONSTRAINED
                            placeholder: BLURRED
                        )
                        title
                    }
                    testimonialType
                    testimonialStatement {
                        testimonialStatement
                    }
                }
                members {
                    id
                    name
                    avatar {
                        gatsbyImageData(quality: 100, width: 176, placeholder: BLURRED)
                        title
                    }
                    colorLogo {
                        gatsbyImageData(quality: 100, placeholder: BLURRED, layout: CONSTRAINED)
                        title
                    }
                    designation
                    founder
                }
            }
        }
        allContentfulCasestudies(
            filter: { type: { eq: "Non-AI" }, services: { elemMatch: { id: { eq: $id } } } }
        ) {
            nodes {
                id
                name
                logo {
                    gatsbyImageData(placeholder: BLURRED, quality: 100)
                    title
                }
                services {
                    id
                    title
                }
                description {
                    description
                }
                doneBy {
                    name
                }
                backgroundType
                backgroundMedia {
                    gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
                    title
                    file {
                        contentType
                        url
                    }
                }
                colorTheme
                thumbnailTheme
                slug
            }
        }
        allContentfulBlogs(filter: { services: { elemMatch: { id: { eq: $id } } } }) {
            nodes {
                title
                description {
                    description
                }
                readTime
                slug
                categories {
                    slug
                    categoryType
                }
                author
                blogImage {
                    gatsbyImageData(quality: 100, placeholder: BLURRED, layout: CONSTRAINED)
                    title
                }
                services {
                    id
                    slug
                    title
                }
            }
        }
        relatedServices: allContentfulServices(
            filter: { id: { in: $relatedPages }, featureInFrontend: { eq: true } }
            limit: 3
        ) {
            nodes {
                id
                title
                slug
            }
        }
    }
`;
