import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

const ServiceClientsLogoBlock = ({ title, description, logos }) => {
    return (
        <div className="client-block">
            <div className="client-block__text">
                <div className="text-h1 text-fw-medium text-clr-primary gap-3x">{title}</div>
                <p className="text-p2 text-fw-regular text-clr-secondary gap-4x">{description}</p>
            </div>
            <div className="client-block__grid">
                {logos.map((l) =>
                    l?.gatsbyImageData ? (
                        <div className="client-block__grid-logo" key={l.id}>
                            <GatsbyImage image={l.gatsbyImageData} alt={l.title} loading="lazy" />
                        </div>
                    ) : (
                        <div className="client-block__grid-logo" key={l.id}>
                            <img src={l.file.url} alt={l.title} loading="lazy" />
                        </div>
                    )
                )}
            </div>
        </div>
    );
};

export default ServiceClientsLogoBlock;
