import React, { useEffect, useState } from "react";
import RubyImage from "../images/product-service/Technologies/Ruby.png";
import RailsImage from "../images/product-service/Technologies/Rails.png";
import PythonImage from "../images/product-service/Technologies/Python.png";
import PHPImage from "../images/product-service/Technologies/PHP.png";
import DotNetImage from "../images/product-service/Technologies/dotnet.png";
import NodejsImage from "../images/product-service/Technologies/Nodejs.png";
import AngularjsImage from "../images/product-service/Technologies/Angularjs.png";
import ReactjsImage from "../images/product-service/Technologies/React.png";
import VuejsImage from "../images/product-service/Technologies/Vuejs.png";
import JavaImage from "../images/product-service/Technologies/Java.png";
import EmperImage from "../images/product-service/Technologies/Ember.png";
import ReduxImage from "../images/product-service/Technologies/Redux.png";
import ExpressJsImage from "../images/product-service/Technologies/ExpressJs.png";
import JQueryImage from "../images/product-service/Technologies/jQuery.png";
import BootstrapImage from "../images/product-service/Technologies/Bootstrap.png";
import HtmlImage from "../images/product-service/Technologies/HTML.png";
import CSSImage from "../images/product-service/Technologies/CSS3.png";
import GolangImage from "../images/product-service/Technologies/Golang.png";
import TestflightImage from "../images/product-service/Technologies/Testflight.png";
import ObjectiveCImage from "../images/product-service/Technologies/Objective-c.png";
import IOSsdkImage from "../images/product-service/Technologies/iOS-sdk.png";
import XcodeImage from "../images/product-service/Technologies/Xcode.png";
import AndroidImage from "../images/product-service/Mobile/Android.png";
import IosImage from "../images/product-service/Mobile/ios.png";
import ReactNativeImage from "../images/product-service/Mobile/ReactNative.png";
import FlutterImage from "../images/product-service/Mobile/Flutter.png";
import SwiftImage from "../images/product-service/Mobile/Swift.png";
import KotlinImage from "../images/product-service/Mobile/Kotlin.png";
import XamarinImage from "../images/product-service/Mobile/Xamarin.png";
import ProgressiveImage from "../images/product-service/Mobile/Progressive-Web-App.png";
import IonicImage from "../images/product-service/Mobile/Ionic.png";
import AWSImage from "../images/product-service/Cloud/AWS-Cloud.png";
import AzureImage from "../images/product-service/Cloud/Azure.png";
import GoogleCloudImage from "../images/product-service/Cloud/Google-Cloud.png";
import RackspaceImage from "../images/product-service/Cloud/Rackspace.png";
import IBMCloudImage from "../images/product-service/Cloud/IBM-Cloud.png";
import LinodeImage from "../images/product-service/Cloud/Linode.png";
import DigitalOceanImage from "../images/product-service/Cloud/Digital-Ocean.png";
import SalesforceImage from "../images/product-service/Cloud/Salesforce.png";
import DockerImage from "../images/product-service/DevOps/Docker.png";
import KubernetesImage from "../images/product-service/DevOps/Kubernetes.png";
import BambooImage from "../images/product-service/DevOps/Bamboo.png";
import JenkinsImage from "../images/product-service/DevOps/Jenkins.png";
import MonitImage from "../images/product-service/DevOps/Monit.png";
import ElasticsearchImage from "../images/product-service/DevOps/Elasticsearch.png";
import KibanaImage from "../images/product-service/DevOps/Kibana.png";
import PuppetImage from "../images/product-service/DevOps/Puppet.png";
import VagrantImage from "../images/product-service/DevOps/Vagrant.png";
import VaultImage from "../images/product-service/DevOps/Vault.png";
import PackerImage from "../images/product-service/DevOps/Packer.png";
import ConsulImage from "../images/product-service/DevOps/Consul.png";
import NomadImage from "../images/product-service/DevOps/Nomad.png";
import TerraformImage from "../images/product-service/DevOps/Terraform.png";
import AnsibleImage from "../images/product-service/DevOps/Ansible.png";
import SumologicImage from "../images/product-service/DevOps/Sumologic.png";
import DatadogImage from "../images/product-service/DevOps/Datadog.png";
import BitbucketImage from "../images/product-service/DevOps/Bitbucket.png";
import GITImage from "../images/product-service/DevOps/GIT.png";
import NginxImage from "../images/product-service/DevOps/Nginx.png";
import NagiosImage from "../images/product-service/DevOps/Nagios.png";
import PagerdutyImage from "../images/product-service/DevOps/Pagerduty.png";
import RunscopeImage from "../images/product-service/DevOps/Runscope.png";
import SplunkImage from "../images/product-service/DevOps/Splunk.png";
import LogicmonitorImage from "../images/product-service/DevOps/Logicmonitor.png";
import MYSQLImage from "../images/product-service/Database/MYSQL.png";
import PostgreSQLImage from "../images/product-service/Database/Postgre-SQL.png";
import OracleImage from "../images/product-service/Database/Oracle.png";
import MongoDBImage from "../images/product-service/Database/MongoDB.png";
import SQLiteImage from "../images/product-service/Database/SQLite.png";
import RealmImage from "../images/product-service/Database/Realm.png";
import NPMImage from "../images/product-service/Package-Management-Build-API/NPM.png";
import YarnImage from "../images/product-service/Package-Management-Build-API/Yarn.png";
import BowerImage from "../images/product-service/Package-Management-Build-API/Bower.png";
import GruntImage from "../images/product-service/Package-Management-Build-API/Grunt.png";
import GulpImage from "../images/product-service/Package-Management-Build-API/Gulp.png";
import MavenImage from "../images/product-service/Package-Management-Build-API/Maven.png";
import GroovyImage from "../images/product-service/Package-Management-Build-API/Groovy.png";
import GrapeImage from "../images/product-service/Package-Management-Build-API/Grape.png";
import PostmanImage from "../images/product-service/Package-Management-Build-API/Postman.png";
import AppiumImage from "../images/product-service/Testing/Appium.png";
import SeleniumImage from "../images/product-service/Testing/Selenium.png";
import CucumberImage from "../images/product-service/Testing/Cucumber.png";
import CalabashImage from "../images/product-service/Testing/Calabash.png";
import BrowserStackImage from "../images/product-service/Testing/BrowserStack.png";
import SauceLabsImage from "../images/product-service/Testing/SauceLabs.png";
import SpoonImage from "../images/product-service/Testing/Spoon.png";
import IETestingImage from "../images/product-service/Testing/IE-Testing.png";
import QMetryImage from "../images/product-service/Testing/QMetry.png";
import SahiImage from "../images/product-service/Testing/Sahi.png";
import SwaggerUIImage from "../images/product-service/Testing/Swagger-UI.png";
import SoapUIImage from "../images/product-service/Testing/SoapUI.png";
import TestCompleteImage from "../images/product-service/Testing/TestComplete.png";
import JMeterImage from "../images/product-service/Testing/JMeter.png";
import NewRelicImage from "../images/product-service/Testing/NewRelic.png";
import YSlowImage from "../images/product-service/Testing/YSlow.png";
import WatirImage from "../images/product-service/Testing/Watir.png";
import RobotiumImage from "../images/product-service/Testing/Robotium.png";
import TestNGImage from "../images/product-service/Testing/TestNG.png";
import ZCronImage from "../images/product-service/Testing/ZCron.png";
import TestrailImage from "../images/product-service/Project-Management/Testrail.png";
import SlackImage from "../images/product-service/Project-Management/Slack.png";
import JiraImage from "../images/product-service/Project-Management/Jira.png";
import MantisImage from "../images/product-service/Project-Management/Mantis.png";
import SequelProImage from "../images/product-service/Project-Management/Sequel-Pro.png";
import RedmineImage from "../images/product-service/Project-Management/Redmine.png";
import TrelloImage from "../images/product-service/Project-Management/Trello.png";
import BasecampImage from "../images/product-service/Project-Management/Basecamp.png";
import { GatsbyImage } from "gatsby-plugin-image";

const ProductTechnologies = ({ initialTech = "Technologies" }) => {
    const [selectedtechbtn, setSelectedTechbtn] = useState(initialTech);
    useEffect(() => {
        setSelectedTechbtn(initialTech);
    }, [initialTech]);
    const handleTechbtnClick = (techBtnHeading) => {
        setSelectedTechbtn(techBtnHeading);
    };
    const techStack = [
        "Technologies",
        "Mobile",
        "Cloud",
        "DevOps",
        "Database",
        "Package Management, Build & API",
        "Testing",
        "Project Management",
    ];
    const techStackLogos = [
        {
            id: 1,
            techBtnHeading: "Technologies",
            imageIcon: RubyImage,
        },
        {
            id: 2,
            techBtnHeading: "Technologies",
            imageIcon: RailsImage,
        },
        {
            id: 3,
            techBtnHeading: "Technologies",
            imageIcon: PythonImage,
        },
        {
            id: 4,
            techBtnHeading: "Technologies",
            imageIcon: PHPImage,
        },
        {
            id: 5,
            techBtnHeading: "Technologies",
            imageIcon: DotNetImage,
        },
        {
            id: 6,
            techBtnHeading: "Technologies",
            imageIcon: NodejsImage,
        },
        {
            id: 7,
            techBtnHeading: "Technologies",
            imageIcon: AngularjsImage,
        },
        {
            id: 8,
            techBtnHeading: "Technologies",
            imageIcon: ReactjsImage,
        },
        {
            id: 9,
            techBtnHeading: "Technologies",
            imageIcon: VuejsImage,
        },
        {
            id: 10,
            techBtnHeading: "Technologies",
            imageIcon: JavaImage,
        },
        {
            id: 11,
            techBtnHeading: "Technologies",
            imageIcon: EmperImage,
        },
        {
            id: 12,
            techBtnHeading: "Technologies",
            imageIcon: ReduxImage,
        },
        {
            id: 13,
            techBtnHeading: "Technologies",
            imageIcon: ExpressJsImage,
        },
        {
            id: 14,
            techBtnHeading: "Technologies",
            imageIcon: JQueryImage,
        },
        {
            id: 15,
            techBtnHeading: "Technologies",
            imageIcon: BootstrapImage,
        },
        {
            id: 16,
            techBtnHeading: "Technologies",
            imageIcon: HtmlImage,
        },
        {
            id: 17,
            techBtnHeading: "Technologies",
            imageIcon: CSSImage,
        },
        {
            id: 18,
            techBtnHeading: "Technologies",
            imageIcon: GolangImage,
        },
        {
            id: 19,
            techBtnHeading: "Technologies",
            imageIcon: TestflightImage,
        },
        {
            id: 20,
            techBtnHeading: "Mobile",
            imageIcon: AndroidImage,
        },
        {
            id: 21,
            techBtnHeading: "Mobile",
            imageIcon: IosImage,
        },
        {
            id: 22,
            techBtnHeading: "Mobile",
            imageIcon: ReactNativeImage,
        },
        {
            id: 23,
            techBtnHeading: "Mobile",
            imageIcon: FlutterImage,
        },
        {
            id: 24,
            techBtnHeading: "Mobile",
            imageIcon: SwiftImage,
        },
        {
            id: 25,
            techBtnHeading: "Mobile",
            imageIcon: KotlinImage,
        },
        {
            id: 26,
            techBtnHeading: "Mobile",
            imageIcon: XamarinImage,
        },
        {
            id: 27,
            techBtnHeading: "Mobile",
            imageIcon: ProgressiveImage,
        },
        {
            id: 28,
            techBtnHeading: "Mobile",
            imageIcon: IonicImage,
        },
        {
            id: 29,
            techBtnHeading: "Cloud",
            imageIcon: AWSImage,
        },
        {
            id: 30,
            techBtnHeading: "Cloud",
            imageIcon: AzureImage,
        },
        {
            id: 31,
            techBtnHeading: "Cloud",
            imageIcon: GoogleCloudImage,
        },
        {
            id: 32,
            techBtnHeading: "Cloud",
            imageIcon: RackspaceImage,
        },
        {
            id: 33,
            techBtnHeading: "Cloud",
            imageIcon: IBMCloudImage,
        },
        {
            id: 34,
            techBtnHeading: "Cloud",
            imageIcon: LinodeImage,
        },
        {
            id: 35,
            techBtnHeading: "Cloud",
            imageIcon: DigitalOceanImage,
        },
        {
            id: 36,
            techBtnHeading: "Cloud",
            imageIcon: SalesforceImage,
        },
        {
            id: 37,
            techBtnHeading: "DevOps",
            imageIcon: DockerImage,
        },
        {
            id: 38,
            techBtnHeading: "DevOps",
            imageIcon: KubernetesImage,
        },
        {
            id: 39,
            techBtnHeading: "DevOps",
            imageIcon: BambooImage,
        },
        {
            id: 40,
            techBtnHeading: "DevOps",
            imageIcon: JenkinsImage,
        },
        {
            id: 41,
            techBtnHeading: "DevOps",
            imageIcon: MonitImage,
        },
        {
            id: 42,
            techBtnHeading: "DevOps",
            imageIcon: ElasticsearchImage,
        },
        {
            id: 43,
            techBtnHeading: "DevOps",
            imageIcon: KibanaImage,
        },
        {
            id: 44,
            techBtnHeading: "DevOps",
            imageIcon: PuppetImage,
        },
        {
            id: 45,
            techBtnHeading: "DevOps",
            imageIcon: VagrantImage,
        },
        {
            id: 46,
            techBtnHeading: "DevOps",
            imageIcon: VaultImage,
        },
        {
            id: 47,
            techBtnHeading: "DevOps",
            imageIcon: PackerImage,
        },
        {
            id: 48,
            techBtnHeading: "DevOps",
            imageIcon: ConsulImage,
        },
        {
            id: 49,
            techBtnHeading: "DevOps",
            imageIcon: NomadImage,
        },
        {
            id: 50,
            techBtnHeading: "DevOps",
            imageIcon: TerraformImage,
        },
        {
            id: 51,
            techBtnHeading: "DevOps",
            imageIcon: AnsibleImage,
        },
        {
            id: 52,
            techBtnHeading: "DevOps",
            imageIcon: SumologicImage,
        },
        {
            id: 53,
            techBtnHeading: "DevOps",
            imageIcon: DatadogImage,
        },
        {
            id: 54,
            techBtnHeading: "DevOps",
            imageIcon: BitbucketImage,
        },
        {
            id: 55,
            techBtnHeading: "DevOps",
            imageIcon: GITImage,
        },
        {
            id: 56,
            techBtnHeading: "DevOps",
            imageIcon: NginxImage,
        },
        {
            id: 57,
            techBtnHeading: "DevOps",
            imageIcon: NagiosImage,
        },
        {
            id: 58,
            techBtnHeading: "DevOps",
            imageIcon: PagerdutyImage,
        },
        {
            id: 59,
            techBtnHeading: "DevOps",
            imageIcon: RunscopeImage,
        },
        {
            id: 60,
            techBtnHeading: "DevOps",
            imageIcon: SplunkImage,
        },
        {
            id: 61,
            techBtnHeading: "DevOps",
            imageIcon: LogicmonitorImage,
        },
        {
            id: 62,
            techBtnHeading: "Database",
            imageIcon: MYSQLImage,
        },
        {
            id: 63,
            techBtnHeading: "Database",
            imageIcon: PostgreSQLImage,
        },
        {
            id: 64,
            techBtnHeading: "Database",
            imageIcon: OracleImage,
        },
        {
            id: 65,
            techBtnHeading: "Database",
            imageIcon: MongoDBImage,
        },
        {
            id: 66,
            techBtnHeading: "Database",
            imageIcon: SQLiteImage,
        },
        {
            id: 67,
            techBtnHeading: "Database",
            imageIcon: RealmImage,
        },
        {
            id: 68,
            techBtnHeading: "Package Management, Build & API",
            imageIcon: NPMImage,
        },
        {
            id: 69,
            techBtnHeading: "Package Management, Build & API",
            imageIcon: YarnImage,
        },
        {
            id: 70,
            techBtnHeading: "Package Management, Build & API",
            imageIcon: BowerImage,
        },
        {
            id: 71,
            techBtnHeading: "Package Management, Build & API",
            imageIcon: GruntImage,
        },
        {
            id: 72,
            techBtnHeading: "Package Management, Build & API",
            imageIcon: GulpImage,
        },
        {
            id: 73,
            techBtnHeading: "Package Management, Build & API",
            imageIcon: MavenImage,
        },
        {
            id: 74,
            techBtnHeading: "Package Management, Build & API",
            imageIcon: GroovyImage,
        },
        {
            id: 75,
            techBtnHeading: "Package Management, Build & API",
            imageIcon: GrapeImage,
        },
        {
            id: 76,
            techBtnHeading: "Package Management, Build & API",
            imageIcon: PostmanImage,
        },
        {
            id: 77,
            techBtnHeading: "Testing",
            imageIcon: AppiumImage,
        },
        {
            id: 78,
            techBtnHeading: "Testing",
            imageIcon: SeleniumImage,
        },
        {
            id: 79,
            techBtnHeading: "Testing",
            imageIcon: CucumberImage,
        },
        {
            id: 80,
            techBtnHeading: "Testing",
            imageIcon: CalabashImage,
        },
        {
            id: 81,
            techBtnHeading: "Testing",
            imageIcon: BrowserStackImage,
        },
        {
            id: 82,
            techBtnHeading: "Testing",
            imageIcon: SauceLabsImage,
        },
        {
            id: 83,
            techBtnHeading: "Testing",
            imageIcon: SpoonImage,
        },
        {
            id: 84,
            techBtnHeading: "Testing",
            imageIcon: IETestingImage,
        },
        {
            id: 85,
            techBtnHeading: "Testing",
            imageIcon: QMetryImage,
        },
        {
            id: 86,
            techBtnHeading: "Testing",
            imageIcon: SahiImage,
        },
        {
            id: 87,
            techBtnHeading: "Testing",
            imageIcon: SwaggerUIImage,
        },
        {
            id: 88,
            techBtnHeading: "Testing",
            imageIcon: SoapUIImage,
        },
        {
            id: 89,
            techBtnHeading: "Testing",
            imageIcon: TestCompleteImage,
        },
        {
            id: 90,
            techBtnHeading: "Testing",
            imageIcon: JMeterImage,
        },
        {
            id: 91,
            techBtnHeading: "Testing",
            imageIcon: NewRelicImage,
        },
        {
            id: 92,
            techBtnHeading: "Testing",
            imageIcon: YSlowImage,
        },
        {
            id: 93,
            techBtnHeading: "Testing",
            imageIcon: WatirImage,
        },
        {
            id: 94,
            techBtnHeading: "Testing",
            imageIcon: RobotiumImage,
        },
        {
            id: 95,
            techBtnHeading: "Testing",
            imageIcon: TestNGImage,
        },
        {
            id: 96,
            techBtnHeading: "Testing",
            imageIcon: ZCronImage,
        },
        {
            id: 97,
            techBtnHeading: "Testing",
            imageIcon: MavenImage,
        },
        {
            id: 98,
            techBtnHeading: "Project Management",
            imageIcon: TestrailImage,
        },
        {
            id: 99,
            techBtnHeading: "Project Management",
            imageIcon: SlackImage,
        },
        {
            id: 100,
            techBtnHeading: "Project Management",
            imageIcon: JiraImage,
        },
        {
            id: 101,
            techBtnHeading: "Project Management",
            imageIcon: MantisImage,
        },
        {
            id: 102,
            techBtnHeading: "Project Management",
            imageIcon: SequelProImage,
        },
        {
            id: 103,
            techBtnHeading: "Project Management",
            imageIcon: RedmineImage,
        },
        {
            id: 104,
            techBtnHeading: "Project Management",
            imageIcon: TrelloImage,
        },
        {
            id: 105,
            techBtnHeading: "Project Management",
            imageIcon: BasecampImage,
        },
        {
            id: 106,
            techBtnHeading: "Technologies",
            imageIcon: ObjectiveCImage,
        },
        {
            id: 107,
            techBtnHeading: "Technologies",
            imageIcon: IOSsdkImage,
        },
        {
            id: 108,
            techBtnHeading: "Technologies",
            imageIcon: XcodeImage,
        },
    ];
    return (
        <div className="product-tool-tech__grid">
            <div className="product-tool-tech__grid-techbtn gap-2x">
                {techStack?.map((tech, i) => (
                    <a
                        key={i}
                        // href=""
                        className={`text-p5 text-fw-medium text-clr-primary text-decore-none product-tool-tech__grid-techbtn-techstackbtn ${
                            selectedtechbtn === tech ? "active" : ""
                        }`}
                        onClick={() => handleTechbtnClick(tech)}
                    >
                        {tech}
                    </a>
                ))}
            </div>
            <div className="product-tool-tech__grid-item">
                {techStackLogos
                    ?.filter((item) => item.techBtnHeading === selectedtechbtn)
                    .map((item, i) => (
                        <div key={i} className="client-block__grid-logo">
                            <div className="client-block__grid-logo-image">
                                {item.imageIcon.gatsbyImageData ? (
                                    <GatsbyImage
                                        className="facility-icon"
                                        image={item.icon.gatsbyImageData}
                                        alt={item.title}
                                    />
                                ) : (
                                    <img
                                        className="facility-icon"
                                        src={item.imageIcon}
                                        alt={item.title}
                                    />
                                )}
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default ProductTechnologies;
